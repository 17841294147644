/**
 * JSON keys to use when reading values from survey data
 */

export const surveyResponseDetails = [
    "sourceId",
    "categories",
    "mostRecentAnswer"
];

export const surveyScheduleDetails = [
    "sourceId",
    "nextSchedule",
    "dueTime"
];

export const surveyResponseHeaders = [
    {
        width: 400,
        label: 'Source ID',
        dataKey: 'sourceId',
    },
    {
        width: 220,
        label: 'Categories',
        dataKey: 'categories',
    },
    {
        width: 240,
        label: 'Most Recent Answer',
        dataKey: 'mostRecentAnswer',
    },
    {
        width: 400,
        label: 'JSON View & Options',
        dataKey: 'sourceId',
    },
]

export const surveyScheduleHeaders = [
    {
        width: 400,
        label: 'Source ID',
        dataKey: 'sourceId',
    },
    {
        width: 220,
        label: 'Next Schedule',
        dataKey: 'nextSchedule',
    },
    {
        width: 240,
        label: 'Due Time',
        dataKey: 'dueTime',
    },
    {
        width: 400,
        label: 'JSON View & Options',
        dataKey: 'sourceId',
    },
]

// TODO: Resolve CORS error when fetching
const getSurveyDefinition = (setQuestionList) => {
    fetch('https://survey-api.sleep-concierge-dev.dht.live/survey/sleep-concierge-v1/definition',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(questionsJson) {
            console.log(questionsJson.survey.questions);
            setQuestionList(questionsJson.survey.questions)
        });
}